body{
  background: rgb(215,215,215);
  background: radial-gradient(circle, rgba(215,215,215,1) 81%, rgba(195,195,195,1) 100%);
}

/* Fonts */
.font-pacifico {
  font-family: "Pacifico" !important;
 }

 .font-filson-pro {
  font-family: "Filson-Pro" !important;
 } 

 .font-filson-bold {
  font-family: "Filson-Bold" !important;
 } 

  .b-brown{
    color: #52301D !important;
  }

  .b-black{
    color: #000 !important;
  }

  .welcome-small{
    font-size: 2.4vh;
    /* font-size: 26.9px; */
    color: #000;
  }
  .page-1 .welcome-big{
    font-size: 10.3704vh;
    /* font-size: 112px; */
    margin-bottom: -2px;
  }

 /* Form Adjustment */
  .form-wrapped {
    padding: 0px 70px 0px 70px;
  }

 .page-1 .form-control {
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #000;
  background-color: transparent !important;
  font-size: 14px;
  padding-bottom: 10px;
  padding-left: 0px;
  }

  .page-1 .form-check-label {
    margin-left: 4.5px;
    color: #000;
}
.page-1 .form-check-label a{
  color: #000;
}

.form-heading{
  font-size: 23px;
  color: #000;
}

.page-1 .form-control::placeholder {
  color: black;
}

/* Form- checkbox */
.page-1 .custom-check input[type="checkbox"]{
  position: absolute;
  cursor: pointer;
  font: inherit;
  /* color: grey; */
  width: 21px;
  border-radius: 0px;
  height: 20px;
  border: 1px solid #fff;
  transform: translateY(-0.075em);
  background-color: #fff;
  display: grid;
  place-content: center;
  margin-left: 60px;
}
/* .page-1 .form-check-input:checked[type=checkbox] {
  background-image: url(images/check-mark.png) !important;  
} */

input[type='checkbox']:checked:after {
  content: '\2713';
  color:#0d6efd !important;
  font-size: 24px;
}

 /* Tick Icon */
 #tick-mark {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
}

#tick-mark::before {
  position: absolute;
  left: 0;
  top: 50%;
  height: 50%;
  width: 3px;
  background-color: #4f2d1a;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

#tick-mark::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: #986f44;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.gg-check {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
  background-color: #986f44;
}
.gg-check::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: -7px;
  width: 8px;
  height: 17px;
  border-width: 0 3px 3px 0;
  border-style: solid;
  transform-origin: bottom left;
  transform: rotate(45deg);
  color: #fff
}

.gg-check::before {
background-color: #986f44;
}


.App {
  text-align: center;
  margin: auto;
  max-width: 60vh;
  background-position: center;
  background-size: cover;
  background-image: url(images/background.png);
  background: #fff;
  min-height: 100vh;
}

.morning-side-bg{
  background-image: url(images/background.png) !important;
  background-position: center;
  background-size: cover;
  /* background-color: #fff; */
}

#page {
  display: grid;
  max-width: 720px;
  height: 100vh;
}

/* Logo */

.page-1 .logo, .connected .logo, .description .logo{
  height: 50px;
  width: 224px;
  max-width: 100%;
}

.page-1 .powered-img, .connected .powered-img{
  width: 73px;
}


/* .page-1  #page{
  grid-template:
  "head" 5%
  "main" 32%
  "nav" 51%
  "foot" 12% / 1fr;
} */

.page-1 #page {
  grid-template:
      "head" 5%
      "main" 44%
      "nav" 39%
      "foot" 12% / 1fr;
}

.description #page{
  grid-template:
  "head" 10%
  "main" 79%
  "nav" 10%
  "foot" 1% / 1fr;
}

.connected #page{
  grid-template:
  "head" 25%
  "main" 53%
  "nav" 10%
  "foot" 12% / 1fr;
}

.page-2 #page {
  grid-template:
      "head" 10%
      "main" 70%
      "nav" 10%
      "foot" 10% / 1fr;
}

.online-page #page{
    grid-template:
        "head" 30%
        "main" 70%
        "nav" 10%
        "foot" 10% / 1fr;

}

.display-linebreak {
  white-space: pre-line;
}


.modal .main-button{
  background-color: #AD00B1 !important;
}

.modal .extend-text{
  padding-top: 23px;
}

.online-direct a{
  text-decoration: none !important; 
  color: #fff !important;
}

.online-page #browseb {
  padding-top: 5px;
}

header {
  grid-area: head / head / head / head;
  justify-self: stretch;
}

main {
  grid-area: main ;
  display: flex;
  flex-direction: column;
}

nav {
  grid-area: nav / nav / nav / nav;
  justify-self: stretch;
} 

footer {
  grid-area: foot / foot/ foot / foot;
  justify-self: stretch;
  margin-top: auto;
}

/* Test Events */

.status,
      .status-row {
        display: inline-table;
        width: 750px;
      }

      .status-container {
        height: 400px;
        overflow: scroll;
        width: 100%;
      }

      .status-row:nth-child(even) {
        background-color: lightsteelblue;
      }

      .status-cell {
        float: left;
        min-width: 15%
      }

      .event {
        width: 50%;
      }

      .d-none{
        display: none;
      }

/* Error Validation */
.error-message {
  color: red !important;
}

/* Error Page & Spinner Page */
.error , .spinner , .return{
    text-align: center;
    margin: auto;
    max-width: 60vh;
    background-position: center;
    background-size: cover;
    background: #fff;
    min-height: 100vh;
}
.spinner #page{
  grid-template:
  "head" 5%
  "main" 70%
  "nav" 10%
  "foot" 15% / 1fr;
}
.error-v #page{
  grid-template:
  "head" 5%
  "main" 70%
  "nav" 10%
  "foot" 15% / 1fr;
}

.col-6-l{
  padding-right: 5px;
}

.col-6-r{
  padding-left: 5px;
}

.row-center{
    display: flex;
    justify-content: center;
    padding-bottom: 21px;
    /* padding-top: 21px */
}

/* Responsive Queries */
@media screen and (max-height: 550px) {

  .page-1 #page {
    grid-template:
        "head" 1%
        "main" 44%
        "nav" 44%
        "foot" 12% / 1fr;
  }

  .page-1 .logo, .connected .logo, .description .logo {
    height: 36px;
    width: 153px;
  }
  .page-1 .form-control {
    padding-bottom: 0px !important;
}
}


@media screen and (max-height: 650px) {
  .button-style{
    margin-top: 0vh !important;
  }

  .page-1 .custom-check input[type="checkbox"] {
    margin-left: 24px;
}

  .page-1 #page {
    grid-template:
        "head" 1%
        "main" 44%
        "nav" 44%
        "foot" 12% / 1fr;
}
}
@media screen and (max-width: 480px) {
  .App{
     max-width: 100vh;
   }
   /* .page-1 .logo, .connected .logo {
    height: 111px;
    width: 265px;
    max-width: 100%;
    } */
   .modal .extend-text {
    padding-top: 14px ;
    }

    .modal-container{
      width: 92vw !important;
      height: 82vh !important; 
    }

    .point-check .Anuao{
      font-size: 1.4vh;
      padding: 0px 0px 0px 26px;
    }

    .page-1 .logo, .connected .logo, .description .logo  {
      height: 50px;
      width: 185px;
      max-width: 100%;
    }
    .page-1 .form-wrapped {
      padding: 0px 28px 0px 30px;
    }
    .page-1 .custom-container{
      margin: 1vh 0vh;
    }
    .point-check {
      margin: 0% 0% !important;
    }
    .page-1 .custom-check input[type="checkbox"] {
      margin-left: 51px;
  }
    
}


@media screen and (max-width: 300px) {
  .App{
    max-width: 100vh;
  }
  .gg-check::before {
    left: 0px;
    top: -3px;
    width: 5px;
    height: 11px;
  }
  .gg-check {
    width: 16px;
    height: 16px;
  }

}